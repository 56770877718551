import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageLinkRow from "../../components/ImageLinkRow"
import ImageDownloadRow from "../../components/ImageDownloadRow"


const IndexPage = () => {
    const root = "/img/download/badges"

    return (
        <Layout pageTitle="Partners - Badges">
            <Row>
                <Col>
                    <Link to="/partners">Back</Link>
                </Col>
            </Row>
            
            <Row className="mt-5 mb-5">
              
            </Row>
            <ImageDownloadRow
                root={root}
                name="Partner Badge"
                filename="partner-badge"
                text="Download your partners badge to display on your website,
                email footer, business cars etc."
                href="https://www.canva.com/design/DAFexXe86E4/ghEorPxbUiYWkhes6uOO6A/view?utm_content=DAFexXe86E4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            />
            
            
        </Layout>
    )
}

export default IndexPage
